import React from "react";
import classnames from "classnames";
import { isEmptyString, noop } from "@util";
import DownloadWorkloadCode from "@components/download-workload-code";
import useGetWorkload from "@hooks/workloadsManager/use-get-workload";
import ExecutionInstancesView from "../components/ExecutionInstancesView";
import {
  AccessRequestType,
  CreateWorkloadRequest,
  DataLakeDataSet,
  SummaryViewData,
  Trigger,
  TriggerType,
  Workload,
  WorkloadCodePackagingStatus,
  WorkloadConfiguration,
  WorkloadInstance,
  WorkloadRuntimeOption,
  WorkloadType,
} from "@data";
import {
  useGetWorkloadCodeHead,
  useGetWorkloadCodePackagingStatus,
  useGetWorkloadConfiguration,
  useGetWorkloadInstances,
  useGetWorkloadTriggers,
  useGetWorkloadV3,
  WorkloadConfigurationOperation,
  WorkloadConfigurationOperations,
} from "@hooks";
import {
  ActionMenuItem,
  ADD_DATA_LAKE_TRIGGER,
  ADD_SCHEDULE_TRIGGER,
  ADD_WORKLOAD_COMPLETE_TRIGGER,
  CLONE_WORKLOAD,
  CODE_ACTIONS,
  DataSetsListItemData,
  DECOMMISSION_WORKLOAD,
  DELETE_VERSION,
  DEPRECATE_WORKLOAD,
  DetailsView,
  DetailsViewRoute,
  DetailsViewTabVariant,
  DOWNLOAD_CODE,
  DRAFT_EXISTING_VERSION,
  DRAFT_LATEST_VERSION,
  DRAFT_NEW_VERSION,
  EDIT_ACTIONS,
  EDIT_DESCRIPTION,
  EDIT_ENTRY_POINT,
  EDIT_POLICY,
  EDIT_QUERY,
  EDIT_TAGS,
  RELEASE_WORKLOAD,
  STATE_ACTIONS,
  SummaryViewActionItems,
  SWITCH_PRINCIPAL,
  TRIGGERS_ACTIONS,
  UPLOAD_CODE,
  UploadWorkloadCode,
  WorkloadAction,
  WorkloadDescriptionDialog,
  WorkloadEntryPointDialog,
  WorkloadKeyValueOperationDialog,
  WorkloadMemoryDialog,
  WorkloadQueryDialog,
  WorkloadTagsDialog,
  WorkloadTimeoutDialog,
  WorkloadTriggerRemoveDialog,
  CompletionMetadataView,
} from "@components";
import { ModuleIcon } from "../components/styles";
import SummaryView from "../components/SummaryView";
import QueryView from "../components/QueryView";
import PolicyView from "../components/PolicyView";
import TriggersView from "../components/TriggersView";
import WorkloadStateHeader from "../components/WorkloadStateHeader";
import EditWorkloadPolicyDialog from "./EditWorkloadPolicyDialog";
import TestViewDrawer from "./TestViewDrawer";
import CodeInfoView from "./CodeInfoView";
import ConfigurationView from "../components/ConfigurationView";

export interface DataWorkloadDetailsContainerModel {
  name: string;
  version: number;
}

export interface DataWorkloadDetailsContainerActions {
  onClickAction?: (action: ActionMenuItem) => void;
  showExecutionHistory: (name: string, version: number) => void;
  showWorkloadExecutionDetails: (name: string, version: number, executionId: string) => void;
  showDataSetDetails: (dataSet: DataSetsListItemData) => void;
  showDataSetDetailsInNewTab: (dataSet: DataSetsListItemData) => string;
  openCloneWorkloadWizard?: (workload: CreateWorkloadRequest) => void;
  addTrigger?: (type: TriggerType) => void;
  editTrigger?: (trigger: Trigger) => void;
  release?: (workload: Workload) => void;
  deprecate?: () => void;
  decommission?: () => void;
  deleteVersion?: () => void;
  switchPrincipal?: () => void;
  draftNewVersion?: (latest: boolean) => void;
  refreshAllDetails?: () => void;
}

type Model = DataWorkloadDetailsContainerModel;
type Actions = DataWorkloadDetailsContainerActions;
type Props = Model & Actions;

const DataWorkloadDetailsContainer = (props: Props) => {

  const {
    name,
    version,
    showDataSetDetails,
    showDataSetDetailsInNewTab,
    showWorkloadExecutionDetails,
    showExecutionHistory,
    onClickAction = noop,
    addTrigger = noop,
    editTrigger = noop,
    release = noop,
    deprecate = noop,
    decommission = noop,
    draftNewVersion = noop,
    deleteVersion = noop,
    switchPrincipal = noop,
    openCloneWorkloadWizard = noop,
    refreshAllDetails = noop,
    ...otherProps
  } = props;

  const showWorkloadInstanceDetails = React.useCallback((instance: WorkloadInstance) =>
      showWorkloadExecutionDetails(name, version, instance.getExecutionId()),
    [name, version, showWorkloadExecutionDetails]);

  const [{
    workload,
    errorMessage,
    showNotFound,
    showErrorView,
    showAccessDenied,
    showLoadingIndicator,
    statusCode,
  }, {refresh: refreshWorkload}] = useGetWorkload({ name, version });

  const [showEditWorkloadPolicyDialog, setShowEditWorkloadPolicyDialog] = React.useState(false);

  const openEditWorkloadPolicyDialog = React.useCallback(() => {
    setShowEditWorkloadPolicyDialog(true);
  }, [setShowEditWorkloadPolicyDialog]);

  const closeEditWorkloadPolicyDialog = React.useCallback(() => {
    setShowEditWorkloadPolicyDialog(false);
  }, [setShowEditWorkloadPolicyDialog]);

  const onEditWorkloadPolicySuccess = React.useCallback(() => {
    closeEditWorkloadPolicyDialog();
    refreshWorkload();
  }, [closeEditWorkloadPolicyDialog, refreshWorkload]);

  // Edit timeout dialog controls
  const [showEditTimeoutDialog, setShowEditTimeoutDialog] = React.useState(false);

  const editTimeout = React.useCallback(() => {
    setShowEditTimeoutDialog(true);
  }, [setShowEditTimeoutDialog]);

  const closeEditTimeoutDialog = React.useCallback(() => {
    setShowEditTimeoutDialog(false);
  }, [setShowEditTimeoutDialog]);

  const onEditTimeoutSuccess = React.useCallback(() => {
    closeEditTimeoutDialog();
    refreshWorkload();
  }, [closeEditTimeoutDialog, refreshWorkload]);

  // Edit memory dialog controls
  const [showEditMemory, setShowEditMemory] = React.useState(false);

  const editMemory = React.useCallback(() => {
    setShowEditMemory(true);
  }, [setShowEditMemory]);

  const closeEditMemory = React.useCallback(() => {
    setShowEditMemory(false);
  }, [setShowEditMemory]);

  const onEditMemorySuccess = React.useCallback(() => {
    closeEditMemory();
    refreshWorkload();
  }, [closeEditMemory, refreshWorkload]);

  const workloadName = React.useMemo(() => workload.getName(), [workload]);

  const workloadVersion = React.useMemo(() => workload.getVersion(), [workload]);

  const workloadEtag = React.useMemo(() => workload.getEtag(), [workload]);

  const workloadPolicy = React.useMemo(() => workload.getPolicy(), [workload]);

  const isFunctionWorkload = React.useMemo(() => workload.isFunctionType(), [workload]);

  const isQueryWorkload = React.useMemo(() => workload.isQueryType(), [workload]);

  const isDraftWorkload = React.useMemo(() => workload.isDraft(), [workload]);

  const isReleasedWorkload = React.useMemo(() => workload.isReleased(), [workload]);

  const isDeprecatedWorkload = React.useMemo(() => workload.isDeprecated(), [workload]);

  const isDecommissionedWorkload = React.useMemo(() => workload.isDecommissioned(), [workload]);

  const [{
    properties: workloadConfigurationProperties,
    etag: workloadConfigurationEtag,
    lastModified: workloadConfigurationLastModified,
    showErrorView: showErrorViewWorkloadConfig,
    showAccessDenied: showAccessDeniedWorkloadConfig,
    showLoadingIndicator: showLoadingIndicatorWorkloadConfig,
    statusCode: workloadConfigurationStatusCode,
    errorMessage: workloadConfigurationErrorMessage,
  }, {refresh: refreshWorkloadConfiguration}] = useGetWorkloadConfiguration({ name, version });

  const [{
    showLoadingIndicator: showLoadingIndicatorInstances,
    ...workloadInstancesModel
  }, {
    refresh: refreshWorkloadInstances,
    ...workloadInstancesActions
  }] = useGetWorkloadInstances({ name, version });

  const [{
    status: codePackagingStatus,
    showLoadingIndicator: loadingPackagingStatus,
    errorMessage: codePackagingError
  }, { refresh: getPackagingStatus }] =
    useGetWorkloadCodePackagingStatus({ name, version, deferRequest: true });

  const codePackaged = React.useMemo(() =>
    codePackagingStatus && codePackagingStatus === WorkloadCodePackagingStatus.SUCCESS,
    [codePackagingStatus]);

  const [{
    eTag,
    lastModified,
    contentLength,
    originalFilename,
    showErrorView: showErrorViewCodeHead,
    showSuccessView: showSuccessViewCodeHead,
    showLoadingIndicator: showLoadingIndicatorCodeHead,
    errorResponse : codeHeadErrorResponse,
  }, { refresh: refreshWorkloadCodeInfo }] =
    useGetWorkloadCodeHead({ name, version, deferRequest: true });

  const showCodeError = React.useMemo(() =>
    isFunctionWorkload && showErrorViewCodeHead && codeHeadErrorResponse?.status !== 404,
    [isFunctionWorkload, showErrorViewCodeHead, codeHeadErrorResponse]);

  const missingCode = React.useMemo(() =>
    isFunctionWorkload && codeHeadErrorResponse?.status === 404,
    [isFunctionWorkload, codeHeadErrorResponse]);

  const codeUploaded = React.useMemo(() =>
    isFunctionWorkload && showSuccessViewCodeHead,
    [isFunctionWorkload, showSuccessViewCodeHead]);

  const [{
    triggers: workloadTriggers,
    showLoadingIndicator: showLoadingIndicatorTriggers,
    ...triggerModel
  }, {
    getTriggers: refreshTriggers,
    ...triggerActions
  }] = useGetWorkloadTriggers({ name, version });

  const refresh = React.useCallback(() => {
    if (isFunctionWorkload) {
      refreshWorkloadCodeInfo();
      getPackagingStatus();
    }
    refreshWorkload();
    refreshWorkloadConfiguration();
    refreshWorkloadInstances();
    refreshTriggers();
  }, [
    isFunctionWorkload,
    refreshWorkload,
    refreshWorkloadConfiguration,
    refreshWorkloadInstances,
    refreshWorkloadCodeInfo,
    getPackagingStatus,
    refreshTriggers,
  ]);

  const errorView = React.useMemo(() => showErrorView || showErrorViewWorkloadConfig,
    [showErrorView, showErrorViewWorkloadConfig]);

  const accessDenied = React.useMemo(() => showAccessDenied || showAccessDeniedWorkloadConfig,
    [showAccessDenied, showAccessDeniedWorkloadConfig]);

  const loadingIndicator = React.useMemo(() =>
    showLoadingIndicator ||
    showLoadingIndicatorCodeHead ||
    loadingPackagingStatus,
    [
      showLoadingIndicator,
      showLoadingIndicatorCodeHead,
      loadingPackagingStatus,
    ]);

  const [ openUploadDialog, setOpenUploadDialog ] = React.useState(false);
  const [ openDownloadDialog, setOpenDownloadDialog ] = React.useState(false);

  const uploadCode = React.useCallback(() => {
    setOpenUploadDialog(true);
  }, [setOpenUploadDialog]);

  const closeUploadDialog = React.useCallback(() => {
    setOpenUploadDialog(false);
  }, [setOpenUploadDialog]);

  const downloadCode = React.useCallback(() => {
    setOpenDownloadDialog(true);
  }, [setOpenDownloadDialog]);

  const closeDownloadDialog = React.useCallback(() => {
    setOpenDownloadDialog(false);
  }, [setOpenDownloadDialog]);

  const codeFileType = React.useMemo(() => {
    return workload.getRuntime() === WorkloadRuntimeOption.PYTHON27 ||
      workload.getRuntime() === WorkloadRuntimeOption.PYTHON37 ? "zip" : "jar";
  }, [workload]);

  const [description, setDescription] = React.useState(workload.getDescription());
  const [openDescriptionDialog, setOpenDescriptionDialog] = React.useState(false);

  const closeDescriptionDialog = React.useCallback(() => {
    setOpenDescriptionDialog(false);
  }, [setOpenDescriptionDialog]);

  const onDescriptionSuccess = React.useCallback(() => {
    closeDescriptionDialog();
    refreshAllDetails();
  }, [closeDescriptionDialog, refreshAllDetails]);

  const editDescription = React.useCallback(() => {
    setOpenDescriptionDialog(true);
  }, [setOpenDescriptionDialog]);

  const [entryPoint, setEntryPoint] = React.useState(workload.getEntryPoint());
  const [openEntryPointDialog, setOpenEntryPointDialog] = React.useState(false);

  const closeEntryPointDialog = React.useCallback(() => {
    setOpenEntryPointDialog(false);
  }, [setOpenEntryPointDialog]);

  const onEntryPointSuccess = React.useCallback(() => {
    closeEntryPointDialog();
    refreshAllDetails();
  }, [closeEntryPointDialog, refreshAllDetails]);

  const editEntryPoint = React.useCallback(() => {
    setOpenEntryPointDialog(true);
  }, [setOpenEntryPointDialog]);

  const [tags, setTags] = React.useState<string[]>(workload.getTags());
  const [openTagsDialog, setOpenTagsDialog] = React.useState(false);

  const closeTagsDialog = React.useCallback(() => {
    setOpenTagsDialog(false);
  }, [setOpenTagsDialog]);

  const onTagsSuccess = React.useCallback(() => {
    closeTagsDialog();
    refreshAllDetails();
  }, [closeTagsDialog, refreshAllDetails]);

  const editTags = React.useCallback(() => {
    setOpenTagsDialog(true);
  }, [setOpenTagsDialog]);

  const [queryData, setQueryData] = React.useState(Workload.EMPTY.toJS().query);
  const [openQueryDialog, setOpenQueryDialog] = React.useState(false);

  const closeQueryDialog = React.useCallback(() => {
    setOpenQueryDialog(false);
    setQueryData(workload.toJS().query);
  }, [workload, setQueryData, setOpenQueryDialog]);

  const onQuerySuccess = React.useCallback(() => {
    closeQueryDialog();
    refreshAllDetails();
  }, [closeQueryDialog, refreshAllDetails]);

  const editQuery = React.useCallback(() => {
    setOpenQueryDialog(true);
  }, [setOpenQueryDialog]);

  React.useEffect(() => {
    if (isFunctionWorkload) {
      refreshWorkloadCodeInfo();
      getPackagingStatus();
    }
    setTags(workload.getTags());
    setDescription(workload.getDescription());
    setEntryPoint(workload.getEntryPoint());
    setQueryData(workload.getQueryData());
  }, [workload]);

  const summaryViewItems: SummaryViewActionItems[] = React.useMemo(() =>
    showLoadingIndicator ? [] : [
      {
        summaryViewItem:
        new SummaryViewData({
          className: "version",
          name: "Version",
          value: workload.getVersionAsString(),
        }),
      },
      {
        summaryViewItem:
        new SummaryViewData({
          className: "workloadState",
          name: "State",
          value: workload.getStateAsString(),
        }),
      },
      {
        summaryViewItem:
          new SummaryViewData({
            className: "workloadStateReason",
            name: "State Reason",
            value: workload.getStateReason(),
          }),
      },
      {
        summaryViewItem:
          new SummaryViewData({
            className: "workloadType",
            name: "Type",
            value: workload.getTypeLabel(),
          }),
      },
      {
        showEditIcon: isDraftWorkload,
        editTooltipText: "Update Description",
        onClickEditIcon: editDescription,
        summaryViewItem:
        new SummaryViewData({
          className: "workloadDescription",
          name: "Description",
          value: !isEmptyString(workload.getDescription()) ? workload.getDescription() : "No Description Provided",
        }),
      },
      ],
    [workload, showLoadingIndicator, isDraftWorkload]);

  const queryViewItems: SummaryViewActionItems[] = React.useMemo(() =>
    showLoadingIndicator ? [] : [
      {
        summaryViewItem:
          new SummaryViewData({
            className: "queryType",
            name: "Type",
            value: workload.getQueryType(),
          }),
      },
      {
        summaryViewItem:
          new SummaryViewData({
            className: "overwrite",
            name: "Overwrite",
            value: workload.isOverwrite().toString().toUpperCase(),
          }),
      },
      {
        summaryViewItem:
          new SummaryViewData({
            className: "upload",
            name: "Upload",
            value: workload.isUpload().toString().toUpperCase(),
            hidden: workload.isInsertQuery(),
          }),
      },
      {
        summaryViewItem:
          new SummaryViewData({
            className: "databaseName",
            name: "Database",
            value: workload.getDatabaseName(),
          }),
      },
      {
        summaryViewItem:
          new SummaryViewData({
            className: "tableName",
            name: "Table",
            value: workload.getTableName(),
          }),
      },
    ], [workload, showLoadingIndicator]);

  const policyView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <PolicyView
        policy={workloadPolicy}
        editButtonDisabled={!isDraftWorkload}
        onClickEditPolicy={openEditWorkloadPolicyDialog}
      />
    );
  }, [
    workloadPolicy,
    isDraftWorkload,
    openEditWorkloadPolicyDialog,
  ]);

  const codeInfoView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <CodeInfoView
        isDraftWorkload={isDraftWorkload}
        fileType={codeFileType}
        eTag={eTag}
        lastModified={lastModified}
        contentLength={contentLength}
        originalFilename={originalFilename}
        workloadName={workloadName}
        version={workloadVersion}
        entryPoint={workload.getEntryPoint()}
        runtime={workload.getRuntime()}
        codePackingStatus={codePackagingStatus}
        codePackagingError={codePackagingError}
        codeUploaded={codeUploaded}
        codeUploadEnabled={!isDraftWorkload}
        editEntryPoint={editEntryPoint}
        refresh={refresh}
      />
    );
  }, [
    isDraftWorkload,
    workloadName,
    workloadVersion,
    eTag,
    lastModified,
    originalFilename,
    workload,
    contentLength,
    codeUploaded,
    isDraftWorkload,
    refresh,
    codePackagingStatus,
    codePackagingError,
  ]);

  const configViewSummaryItems = React.useMemo(() =>
      loadingIndicator ? [] : [
        {
          showEditIcon: isDraftWorkload,
          editTooltipText: "Update Timeout",
          onClickEditIcon: editTimeout,
          summaryViewItem:
            new SummaryViewData({
              className: "workloadTimeout",
              name: "Timeout",
              value: workload.getType() === WorkloadType.FUNCTION ? workload.getTimeoutAsString() : "",
            }),
        },
        {
          showEditIcon: isDraftWorkload,
          editTooltipText: "Update Memory",
          onClickEditIcon: editMemory,
          summaryViewItem:
            new SummaryViewData({
              className: "workloadMemory",
              name: "Memory",
              value: workload.getType() === WorkloadType.FUNCTION ? workload.getMemoryAsString() : "",
            }),
        },
        {
          showEditIcon: false,
          editTooltipText: "",
          onClickEditIcon: noop,
          summaryViewItem:
            new SummaryViewData({
              className: "lastModified",
              name: "Last Modified",
              value: workloadConfigurationLastModified,
            })
        },
        {
          showEditIcon: false,
          editTooltipText: "",
          onClickEditIcon: noop,
          summaryViewItem:
            new SummaryViewData({
              className: "configEtag",
              name: "ETag",
              value: workloadConfigurationEtag,
            })
        },
      ],
    [
      loadingIndicator,
      isDraftWorkload,
      editTimeout,
      editMemory,
      workload,
      workloadConfigurationLastModified,
      workloadConfigurationEtag
    ]);

  const [openConfigurationDialog, setOpenConfigurationDialog] = React.useState(false);
  const [configOperation, setConfigOperation] = React.useState(WorkloadConfigurationOperation.NONE);
  const [workloadConfig, setWorkloadConfig] = React.useState(WorkloadConfiguration.EMPTY);

  const addConfig = React.useCallback(() => {
    setConfigOperation(WorkloadConfigurationOperation.ADD);
    setOpenConfigurationDialog(true);
  }, [setConfigOperation, setOpenConfigurationDialog]);

  const deleteAll = React.useCallback(() => {
    setConfigOperation(WorkloadConfigurationOperation.DELETE_KEYS);
    setOpenConfigurationDialog(true);
  }, [setConfigOperation, setOpenConfigurationDialog]);

  const deleteByKey = React.useCallback((config: WorkloadConfiguration) => {
    setConfigOperation(WorkloadConfigurationOperation.DELETE_BY_KEY);
    setOpenConfigurationDialog(true);
    setWorkloadConfig(config);
  }, [setConfigOperation, setOpenConfigurationDialog, setWorkloadConfig]);

  const edit = React.useCallback((config: WorkloadConfiguration) => {
    setConfigOperation(WorkloadConfigurationOperation.EDIT);
    setOpenConfigurationDialog(true);
    setWorkloadConfig(config);
  }, [setConfigOperation, setOpenConfigurationDialog, setWorkloadConfig]);

  const closeConfigurationDialog = React.useCallback(() => {
    setConfigOperation(WorkloadConfigurationOperation.NONE);
    setWorkloadConfig(WorkloadConfiguration.EMPTY);
    return setOpenConfigurationDialog(false);
  }, [setConfigOperation, setWorkloadConfig, setOpenConfigurationDialog]);

  const onConfigurationOperationSuccess = React.useCallback(() => {
    closeConfigurationDialog();
    return refresh();
  }, [refresh, closeConfigurationDialog]);

  const workloadConfigurationKeys = React.useMemo<string[]>(() =>
    Object.keys(workloadConfigurationProperties),
    [workloadConfigurationProperties]);

  const workloadConfiguration = React.useMemo<WorkloadConfiguration[]>(() => workloadConfigurationKeys
    .map((key) => new WorkloadConfiguration({key, value: workloadConfigurationProperties[key]})),
    [workloadConfigurationKeys, workloadConfigurationProperties]);

  const configDialogClassName = React.useMemo(() => {
    switch (configOperation) {
      case WorkloadConfigurationOperations.ADD:
        return "addConfigurationDialog";
      case WorkloadConfigurationOperations.EDIT:
        return "editConfigurationDialog";
      case WorkloadConfigurationOperations.DELETE_BY_KEY:
        return "deleteByKeyConfigurationDialog";
      case WorkloadConfigurationOperations.DELETE_KEYS:
        return "deleteAllConfigurationDialog";
      default:
        return "workloadConfigurationDialog";
    }
  }, [configOperation]);

  const [{ workload: workloadV3 }, {refresh: refreshWorkloadV3}] = useGetWorkloadV3({ name, version });

  const configurationsView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <ConfigurationView
        workloadName={workloadName}
        version={version}
        etag={workloadEtag}
        summaryViewItems={configViewSummaryItems}
        internetAccessConfiguration={workloadV3.getInternetAccessConfiguration()}
        configuration={workloadConfiguration}
        addConfigurationButtonDisabled={!isDraftWorkload}
        addConfiguration={addConfig}
        deleteAllButtonDisabled={!isDraftWorkload}
        deleteAll={deleteAll}
        showEditConfigButton={isDraftWorkload}
        editConfig={edit}
        showDeleteConfigButton={isDraftWorkload}
        deleteConfig={deleteByKey}
        showLoadingIndicator={showLoadingIndicatorWorkloadConfig}
        refresh={refreshWorkloadConfiguration}
        refreshWorkload={refreshWorkloadV3}
        error={workloadConfigurationErrorMessage}
        statusCode={workloadConfigurationStatusCode}
      />
    );
  }, [
    workloadName,
    version,
    workloadEtag,
    isDraftWorkload,
    workloadConfiguration,
    configViewSummaryItems,
    showLoadingIndicatorWorkloadConfig,
    refreshWorkloadConfiguration,
    deleteAll,
    deleteByKey,
    edit,
    addConfig,
    workloadV3,
    refreshWorkloadV3,
    workloadConfigurationErrorMessage,
    workloadConfigurationStatusCode,
  ]);

  const [openTriggerOperationDialog, setOpenTriggerOperationDialog] = React.useState(false);
  const [triggers, setTriggers] = React.useState<string[]>([]);
  const [triggerNames, setTriggerNames] = React.useState<string[]>([]);
  const [removeAll, setRemoveAll] = React.useState(false);

  const removeTrigger = React.useCallback((trigger: Trigger) => {
    setTriggers([trigger.getId()]);
    setTriggerNames([trigger.getName()]);
    setRemoveAll(false);
    setOpenTriggerOperationDialog(true);
  }, [setTriggers, setTriggerNames, setRemoveAll, setOpenTriggerOperationDialog]);

  const closeTriggerDialog = React.useCallback(() => {
    setOpenTriggerOperationDialog(false);
  }, [setOpenTriggerOperationDialog]);

  const onTriggerOperationSuccess = React.useCallback(() => {
    closeTriggerDialog();
    refreshTriggers();
  }, [closeTriggerDialog, refreshTriggers]);

  const removeAllTriggers = React.useCallback(() => {
    setTriggerNames([]);
    setTriggers(workloadTriggers.map(trigger => trigger.getId()));
    setRemoveAll(true);
    setOpenTriggerOperationDialog(true);
  }, [setTriggerNames, setTriggers, workloadTriggers, setRemoveAll, setOpenTriggerOperationDialog]);

  const [showTestView, setShowTestView] = React.useState(false);
  const [testTrigger, setTestTrigger] = React.useState(Trigger.EMPTY);

  const testWorkloadTrigger = React.useCallback((trigger: Trigger) => {
    setTestTrigger(trigger);
    setShowTestView(true);
  }, [setShowTestView, setTestTrigger]);

  const scheduleTriggerButtonDisabled = React.useMemo(() => {
    return !isDraftWorkload || workloadTriggers.some(trigger => trigger.type === TriggerType.SCHEDULE);
  }, [isDraftWorkload, workloadTriggers]);

  const dataLakeTriggerButtonDisabled = React.useMemo(() => {
    return !isDraftWorkload || workloadTriggers.filter(trigger => trigger.type === TriggerType.DATA_LAKE).length
      === workload.getDataInputs().length;
  }, [isDraftWorkload, workloadTriggers, workload]);

  const triggersView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <TriggersView
        {...triggerModel}
        {...triggerActions}
        showLoadingIndicator={showLoadingIndicatorTriggers}
        triggers={workloadTriggers}
        workloadName={workloadName}
        version={workloadVersion}
        scheduleTriggerButtonDisabled={scheduleTriggerButtonDisabled}
        dataLakeTriggerButtonDisabled={dataLakeTriggerButtonDisabled}
        workloadCompleteTriggerButtonDisabled={!isDraftWorkload}
        addTrigger={addTrigger}
        editTrigger={editTrigger}
        removeAllTriggers={removeAllTriggers}
        testTriggerButtonDisabled={!isReleasedWorkload &&
          !(isDraftWorkload && (isFunctionWorkload ? codePackaged : true))}
        testTrigger={testWorkloadTrigger}
        disableEdit={!isDraftWorkload}
        removeTrigger={removeTrigger}
        refresh={refreshTriggers}
      />
    );
  }, [
    isDraftWorkload,
    isReleasedWorkload,
    isFunctionWorkload,
    codePackaged,
    workloadName,
    workloadVersion,
    triggerModel,
    triggerActions,
    workloadTriggers,
    triggers,
    showLoadingIndicatorTriggers,
    scheduleTriggerButtonDisabled,
    dataLakeTriggerButtonDisabled,
    addTrigger,
    editTrigger,
    removeTrigger,
    removeAllTriggers,
    testWorkloadTrigger,
    refreshTriggers,
  ]);

  const completionMetadataView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <CompletionMetadataView
        workloadName={workloadName}
        version={workloadVersion}
        etag={workload.getEtag()}
        isDraftWorkload={isDraftWorkload}
        refreshDetails={refreshWorkload}
        showLoadingIndicator={showLoadingIndicator}
        actions={workload.getWorkloadCompletionMetadataActions()}
        variables={workload.getWorkloadCompletionMetadataVariableSchemas()}
      />
    );
  }, [
    workload,
    workloadName,
    workloadVersion,
    isDraftWorkload,
    showLoadingIndicator,
    refreshWorkload,
  ]);

  const executionInstancesView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <ExecutionInstancesView
        {...workloadInstancesModel}
        {...workloadInstancesActions}
        name={name}
        version={version}
        showLoadingIndicator={showLoadingIndicatorInstances}
        refresh={refreshWorkloadInstances}
        showExecutionInstanceDetails={showWorkloadInstanceDetails}
      />
    );
  }, [
    workloadInstancesModel,
    workloadInstancesActions,
    name,
    version,
    showLoadingIndicatorInstances,
    refreshWorkloadInstances,
    showWorkloadInstanceDetails,
  ]);

  const actionMenuItems = React.useMemo(() => ([
    {
      ...DRAFT_NEW_VERSION,
      actions: [
        {
          ...DRAFT_EXISTING_VERSION,
          name: `From Version ${version}`
        },
        {
          ...DRAFT_LATEST_VERSION,
        },
      ],
    },
    {
      ...EDIT_ACTIONS,
      actions: [
        {
          ...EDIT_POLICY,
          disabled: !isDraftWorkload,
        },
        {
          ...EDIT_DESCRIPTION,
          disabled: !isDraftWorkload,
        },
        {
          ...EDIT_ENTRY_POINT,
          disabled: !isDraftWorkload,
          hidden: isQueryWorkload,
        },
        {
          ...EDIT_QUERY,
          disabled: !isDraftWorkload,
          hidden: isFunctionWorkload,
        },
        {
          ...EDIT_TAGS,
          disabled: !isDraftWorkload,
        },
      ],
    },
    {
      ...CODE_ACTIONS,
      actions: [
        {
          ...UPLOAD_CODE,
          disabled: !isDraftWorkload,
        },
        {
          ...DOWNLOAD_CODE,
          disabled: !codeUploaded,
        },
      ],
      hidden: !isFunctionWorkload,
    },
    {
      ...TRIGGERS_ACTIONS,
      actions: [
        {
          ...ADD_DATA_LAKE_TRIGGER,
          disabled: dataLakeTriggerButtonDisabled,
        },
        {
          ...ADD_SCHEDULE_TRIGGER,
          disabled: scheduleTriggerButtonDisabled,
        },
        {
          ...ADD_WORKLOAD_COMPLETE_TRIGGER,
          disabled: !isDraftWorkload,
        },
      ],
    },
    {
      ...STATE_ACTIONS,
      actions: [
        {
          ...RELEASE_WORKLOAD,
          disabled: !(isDraftWorkload || isDeprecatedWorkload),
        },
        {
          ...DEPRECATE_WORKLOAD,
          disabled: !isReleasedWorkload,
        },
        {
          ...DECOMMISSION_WORKLOAD,
          disabled: !(isReleasedWorkload || isDeprecatedWorkload),
        },
      ],
    },
    CLONE_WORKLOAD,
    {
      ...SWITCH_PRINCIPAL,
      disabled: !isReleasedWorkload,
    },
    {
      ...DELETE_VERSION,
      disabled: !isDraftWorkload && !isDecommissionedWorkload
    },
  ]), [
    version,
    workload,
    codeUploaded,
    isDraftWorkload,
    isReleasedWorkload,
    isFunctionWorkload,
    isDecommissionedWorkload,
    isDeprecatedWorkload,
    isFunctionWorkload,
    isQueryWorkload,
    scheduleTriggerButtonDisabled,
    dataLakeTriggerButtonDisabled,
  ]);

  const hideValidationView = React.useMemo(() =>
    showLoadingIndicator || showLoadingIndicatorCodeHead,
    [showLoadingIndicator, showLoadingIndicatorCodeHead]);

  const summaryView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <SummaryView
        summaryViewItems={summaryViewItems}
        tags={workload.getTags()}
        dataInputs={workload.getDataInputs()}
        dataOutputs={workload.getDataOutputs()}
        loading={showLoadingIndicator}
        hideValidationView={hideValidationView}
        showCodeError={showCodeError}
        missingCode={missingCode}
        showDataSetDetails={showDataSetDetails}
        showDataSetDetailsInNewTab={showDataSetDetailsInNewTab}
        showEditTags={isDraftWorkload}
        queryWorkload={isQueryWorkload}
        workloadFailure={workload.isWorkloadInFailedState()}
        failureReason={workload.getStateReason()}
        editTags={editTags}
        uploadCode={uploadCode}
      />
    );
  }, [
    summaryViewItems,
    workload,
    showLoadingIndicator,
    hideValidationView,
    showCodeError,
    missingCode,
    isDraftWorkload,
    isQueryWorkload,
    showDataSetDetails,
    showDataSetDetailsInNewTab,
    editTags,
    uploadCode,
  ]);

  const queryView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <QueryView
        disableEdit={!isDraftWorkload}
        query={workload.getQuery()}
        queryViewItems={queryViewItems}
        onClickEditIcon={editQuery}
      />
    );
  }, [isDraftWorkload, workload, queryViewItems, editQuery]);

  const dataSources = React.useMemo(() => workload.getDataInputs().map((source) =>
    new DataLakeDataSet({...source, accessRequestType: AccessRequestType.READ})), [workload]);

  const ROUTES: DetailsViewRoute[] = React.useMemo(() => [
    {
      id: "summary",
      name: "Summary",
      view: summaryView,
    },
    {
      id: "queryView",
      name: "Query",
      path: "/query",
      view: queryView,
      hidden: !isQueryWorkload,
    },
    {
      id: "codeInfoView",
      name: "Code",
      path: "/code",
      view: codeInfoView,
      hidden: !isFunctionWorkload,
    },
    {
      id: "policyView",
      name: "Policy",
      path: "/policy",
      view: policyView,
    },
    {
      id: "configuration",
      name: "Configuration",
      path: "/configuration",
      view: configurationsView,
    },
    {
      id: "triggers",
      name: "Triggers",
      path: "/triggers",
      view: triggersView,
    },
    {
      id: "completionMetadata",
      name: "Completion Metadata",
      path: "/completion-metadata",
      view: completionMetadataView,
    },
    {
      id: "instances",
      name: "Execution History",
      path: "/executions",
      view: executionInstancesView,
    },
  ], [
    summaryView,
    queryView,
    codeInfoView,
    policyView,
    configurationsView,
    triggersView,
    completionMetadataView,
    executionInstancesView,
    isFunctionWorkload,
    isQueryWorkload,
    isFunctionWorkload,
  ]);

  const cloneWorkload = React.useCallback(() => {
    openCloneWorkloadWizard(workload);
  }, [openCloneWorkloadWizard, workload]);

  const onClickWorkloadAction = React.useCallback((action: ActionMenuItem) => {
    switch (action.id) {
      case WorkloadAction.CLONE_WORKLOAD:
        return cloneWorkload();
      case WorkloadAction.EDIT_POLICY:
        return openEditWorkloadPolicyDialog();
      case WorkloadAction.EDIT_DESCRIPTION:
        return editDescription();
      case WorkloadAction.EDIT_ENTRY_POINT:
        return editEntryPoint();
      case WorkloadAction.EDIT_TAGS:
        return editTags();
      case WorkloadAction.EDIT_QUERY:
        return editQuery();
      case WorkloadAction.UPLOAD_CODE:
        return uploadCode();
      case WorkloadAction.DOWNLOAD_CODE:
        return downloadCode();
      case WorkloadAction.ADD_SCHEDULE_TRIGGER:
        return addTrigger(TriggerType.SCHEDULE);
      case WorkloadAction.ADD_DATA_LAKE_TRIGGER:
        return addTrigger(TriggerType.DATA_LAKE);
      case WorkloadAction.ADD_WORKLOAD_COMPLETE_TRIGGER:
        return addTrigger(TriggerType.WORKLOAD_COMPLETE);
      case WorkloadAction.DRAFT_LATEST_VERSION:
        return draftNewVersion(true);
      case WorkloadAction.DRAFT_EXISTING_VERSION:
        return draftNewVersion(false);
      case WorkloadAction.RELEASE:
        return release(workload);
      case WorkloadAction.DEPRECATE:
        return deprecate();
      case WorkloadAction.DECOMMISSION:
        return decommission();
      case WorkloadAction.DELETE_VERSION:
        return deleteVersion();
      case WorkloadAction.SWITCH_PRINCIPAL:
        return switchPrincipal();
      default:
        return onClickAction(action);
    }
  }, [
    workload,
    cloneWorkload,
    openEditWorkloadPolicyDialog,
    editDescription,
    editEntryPoint,
    editTags,
    editQuery,
    uploadCode,
    downloadCode,
    addTrigger,
    release,
    deprecate,
    draftNewVersion,
    decommission,
    onClickAction,
    deleteVersion,
    switchPrincipal,
  ]);

  const onSuccess = React.useCallback(() => {
    closeUploadDialog();
    refresh();
  }, [refresh, closeUploadDialog]);

  const testWorkload = React.useCallback(() => {
    setTestTrigger(new Trigger({type: TriggerType.MANUAL_EXECUTE}));
    setShowTestView(true);
  }, [setTestTrigger, setShowTestView]);

  const showExecutionHistoryView = React.useCallback(() => {
    showExecutionHistory(name, version);
    refreshWorkloadInstances();
  }, [name, version, showExecutionHistory, refreshWorkloadInstances]);

  return (
    <React.Fragment>
      <DetailsView
        {...otherProps}
        className={classnames("dataWorkloadDetails", { loading: loadingIndicator })}
        tabsMarginTop={false}
        showHeader={false}
        title={name}
        routes={ROUTES}
        icon={ModuleIcon}
        refresh={refresh}
        showNotFound={showNotFound}
        errorMessage={errorMessage}
        showErrorView={errorView}
        showAccessDenied={accessDenied}
        showLoadingIndicator={loadingIndicator}
        tabVariant={DetailsViewTabVariant.TABS}
        subheader={(
          <WorkloadStateHeader
            title={`Workload Version ${workloadVersion}`}
            state={workload.getState()}
            actions={actionMenuItems}
            codePackaged={codePackaged}
            showLoadingIndicator={loadingIndicator}
            onClickAction={onClickWorkloadAction}
            testWorkload={testWorkload}
          />
        )}
        statusCode={statusCode}
      />
      {showTestView && (
        <TestViewDrawer
          name={workloadName}
          version={workloadVersion}
          dataSets={dataSources}
          showTestView={showTestView}
          setShowTestView={setShowTestView}
          testTrigger={testTrigger}
          isFunctionWorkload={isFunctionWorkload}
          showExecutionHistoryView={showExecutionHistoryView}
          showExecutionInstanceDetails={showWorkloadExecutionDetails}
        />
      )}
      <UploadWorkloadCode
        name={name}
        version={version}
        open={openUploadDialog}
        closeDialog={closeUploadDialog}
        onSuccess={onSuccess}
        fileType={codeFileType}
      />
      <DownloadWorkloadCode
        dialogClassName={"downloadWorkloadCode"}
        open={openDownloadDialog}
        version={workloadVersion}
        workloadName={workloadName}
        cancel={closeDownloadDialog}
        fileType={codeFileType}
      />
      <WorkloadKeyValueOperationDialog
        dialogClassName={configDialogClassName}
        open={openConfigurationDialog}
        name={name}
        version={version}
        operation={configOperation}
        keyValue={workloadConfig.getKey()}
        value={workloadConfig.getValue()}
        keys={workloadConfigurationKeys}
        onSuccessMessageShown={onConfigurationOperationSuccess}
        cancel={closeConfigurationDialog}
      />
      <WorkloadTriggerRemoveDialog
        name={name}
        version={version}
        eventSources={triggers}
        eventSourceNames={triggerNames}
        removeAll={removeAll}
        open={openTriggerOperationDialog}
        cancel={closeTriggerDialog}
        onSuccessMessageShown={onTriggerOperationSuccess}
      />
      <WorkloadDescriptionDialog
        description={description}
        setDescription={setDescription}
        open={openDescriptionDialog}
        name={name}
        version={version}
        cancel={closeDescriptionDialog}
        onSuccessMessageShown={onDescriptionSuccess}
      />
      <WorkloadEntryPointDialog
        entryPoint={entryPoint}
        setEntryPoint={setEntryPoint}
        open={openEntryPointDialog}
        name={name}
        version={version}
        etag={workloadEtag}
        cancel={closeEntryPointDialog}
        onSuccessMessageShown={onEntryPointSuccess}
      />
      <WorkloadTagsDialog
        tags={tags}
        setTags={setTags}
        open={openTagsDialog}
        name={name}
        version={version}
        cancel={closeTagsDialog}
        onSuccessMessageShown={onTagsSuccess}
      />
      <WorkloadQueryDialog
        name={name}
        version={version}
        workload={workload}
        queryData={queryData}
        open={openQueryDialog}
        cancel={closeQueryDialog}
        setQueryData={setQueryData}
        onSuccessMessageShown={onQuerySuccess}
      />
      <EditWorkloadPolicyDialog
        open={showEditWorkloadPolicyDialog}
        workloadName={workloadName}
        workloadVersion={workloadVersion}
        workloadEtag={workloadEtag}
        policy={workloadPolicy}
        cancel={closeEditWorkloadPolicyDialog}
        onSuccessMessageShown={onEditWorkloadPolicySuccess}
      />
      <WorkloadTimeoutDialog
        open={showEditTimeoutDialog}
        name={workloadName}
        version={workloadVersion}
        etag={workloadEtag}
        timeout={workload.getTimeout()}
        cancel={closeEditTimeoutDialog}
        onSuccessMessageShown={onEditTimeoutSuccess}
      />
      <WorkloadMemoryDialog
        open={showEditMemory}
        name={workloadName}
        version={workloadVersion}
        etag={workloadEtag}
        memory={workload.getMemory()}
        cancel={closeEditMemory}
        onSuccessMessageShown={onEditMemorySuccess}
      />
    </React.Fragment>
  );
};

export default DataWorkloadDetailsContainer;
